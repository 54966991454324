import { DiscardReasons, FieldDefinitionsDto, IdeaAttributeDto, IdeaDto, UserDto } from "api-shared";
import moment from "moment";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hooks/useLanguage";
import { formatUserFromId, removeLineBreaks } from "../../../lib/formatters";
import { replaceImage } from "../../../lib/history";
import { replaceMentionUsers } from "../../../lib/mention";
import { useIdeaAttributeTranslations } from "./useIdeaAttributeTranslations";
import { useIdeaEstimateConversion } from "./useIdeaEstimates";
import { useIdeaStatusTranslation } from "./useIdeaStatusTranslation";

export function useIdeaDataFormatter(attributes: IdeaAttributeDto[], fieldDefinitions: FieldDefinitionsDto, users: UserDto[] = []) {
    const { t: translate } = useTranslation();
    const language = useLanguage();
    const conversion = useIdeaEstimateConversion();
    const attributeTranslations = useIdeaAttributeTranslations(attributes);
    const translateIdeaStatus = useIdeaStatusTranslation();

    const resolveIdeaFields = useCallback(
        (ideaFields: IdeaDto["fields"]): Record<string, unknown> => {
            const fields: Record<string, unknown> = {};
            Object.keys(ideaFields).forEach((key) => {
                const field = fieldDefinitions[key];
                if (field === undefined || field.attributeName === undefined) {
                    return;
                }
                fields[key] = attributeTranslations[key].find(({ id }) => id == ideaFields[key])?.value;
            });
            return fields;
        },
        [attributeTranslations, fieldDefinitions],
    );

    function formatDateForExport(value: unknown) {
        if (value == null) {
            return null;
        }
        return moment.utc(value).format("YYYY-MM-DD HH:mm:ss");
    }

    const dataFormatter = useCallback(
        (data: IdeaDto[]) => {
            return data.map((idea) => {
                const {
                    potentialValue: potentialEstimate,
                    timeValue: timeEstimate,
                    effortValue: effortEstimate,
                } = conversion({
                    potentialEstimateValue: idea.potentialEstimate,
                    timeEstimateValue: idea.timeEstimate,
                    effortEstimateValue: idea.effortEstimate,
                });

                const fields = resolveIdeaFields(idea.fields);

                const discardReason = idea.discardReason != null ? DiscardReasons.find(({ id }) => id == idea.discardReason) : null;
                const translatedDiscardReason = discardReason == null ? "" : discardReason[language];
                const ownerId =
                    idea.ownerId != null ? formatUserFromId(Number(idea.ownerId), users, { translate }) : translate("deleted_user");

                const description = removeLineBreaks(replaceImage(replaceMentionUsers(users, idea.description, translate)));

                const discardStatement =
                    idea.discardStatement != null ? replaceMentionUsers(users, idea.discardStatement, translate) : null;

                const {
                    reactions,
                    status,
                    createdAt,
                    lastModificationAt,
                    convertedAt,
                    discardedAt,
                    title,
                    ...ideaWithoutReactionsAndStatus
                } = idea;

                const reactionsCount = reactions.length;

                const translatedStatus = translateIdeaStatus(status);

                const ideaTitle = removeLineBreaks(title);

                return {
                    ...ideaWithoutReactionsAndStatus,
                    title: ideaTitle,
                    discardStatement,
                    discardReason: translatedDiscardReason,
                    description,
                    ownerId,
                    potentialEstimate,
                    timeEstimate,
                    effortEstimate,
                    reactionsCount,
                    status: translatedStatus,
                    createdAt: formatDateForExport(createdAt),
                    lastModificationAt: formatDateForExport(lastModificationAt),
                    convertedAt: formatDateForExport(convertedAt),
                    discardedAt: formatDateForExport(discardedAt),
                    ...fields,
                };
            });
        },
        [conversion, language, resolveIdeaFields, translate, translateIdeaStatus, users],
    );

    return {
        dataFormatter,
    };
}
