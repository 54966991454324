import { FormControlLabel, FormGroup, InputAdornment, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { EXPORT_LOCALES, ExportLocale, FieldDefinitionsDto } from "api-shared";
import { ChangeEvent, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import useCurrency from "../../hooks/useCurrency";
import { useLanguage } from "../../hooks/useLanguage";
import { useTranslateLocale } from "../../hooks/useTranslateLocale";
import { reportError } from "../../infrastructure/sentry";
import { trackEvent } from "../../infrastructure/tracking";
import { getDefaultLanguage } from "../../lib/bootstrap";
import { parse } from "../../lib/csv-parse";
import { defaultFilename, download } from "../../lib/trigger-csv-download";
import { translationKeys } from "../../translations/main-translations";
import Select from "../input/select/Select";
import { Option } from "../input/select/types";
import ActionItemDialog, { IActionItemDialogProps } from "./ActionItemDialog";

interface ExportCsvDialogProps extends Omit<IActionItemDialogProps, "onPrimary" | "translate"> {
    columns: string[];
    columnsTranslationPrefix: string;
    fieldNames?: string[];
    data: any;
    dataFormatter?: (data: any) => any;
    description?: string;
    fieldDefinitions: FieldDefinitionsDto;
    options?: Record<string, unknown>;
    trackEventName?: string;
}

const ExportCsvDialog = ({
    children,
    columns,
    columnsTranslationPrefix,
    fieldNames,
    data,
    dataFormatter,
    description,
    fieldDefinitions,
    options,
    trackEventName,
    ...actionItemDialogProps
}: PropsWithChildren<ExportCsvDialogProps>) => {
    const { t } = useTranslation();
    const [optimizeForPrint, setOptimizeForPrint] = useState(false);
    const [isExcelMetadataEnabled, setIsExcelMetadataEnabled] = useState(false);
    const [filename, setFilename] = useState(defaultFilename);
    const [selectedLanguage, setSelectedLanguage] = useState<ExportLocale>(
        EXPORT_LOCALES.find((locale) => locale.startsWith(getDefaultLanguage())) ?? "en-gb",
    );
    const { currencyIsoCode } = useCurrency({ language: selectedLanguage });
    const userLang = useLanguage();
    const { translateLocale } = useTranslateLocale(userLang);

    const fields = columns.map((column) => {
        return {
            label: fieldNames?.includes(column)
                ? t(`${columnsTranslationPrefix}.${column}`, { clientCurrency: currencyIsoCode })
                : t(column),
            value: column,
        };
    });
    const languageOptions = EXPORT_LOCALES.map((lang) => ({
        label: translateLocale(lang) ?? "",
        value: lang,
    }));
    const selectedLanguageOption = languageOptions.find((o) => selectedLanguage.startsWith(o.value));

    function handleLanguageChange(option: Option<ExportLocale> | null) {
        if (option == null) {
            return;
        }
        setSelectedLanguage(option.value);
    }

    function parseAndDownload() {
        if (trackEventName !== undefined) {
            trackEvent({ category: "Export", action: "Csv", name: trackEventName });
        }

        const opts = {
            fields,
            ...options,
        };

        try {
            const formattedData = dataFormatter?.(data) ?? data;
            const csv = parse(formattedData, opts, optimizeForPrint, isExcelMetadataEnabled);
            download(csv, filename);
        } catch (error) {
            reportError(error instanceof Error ? error : new Error("Error generating CSV export"), {
                extras: { cause: JSON.stringify(error) },
            });
        }
    }

    return (
        <ActionItemDialog
            title={t(translationKeys.VDLANG_EXPORT_CSV)}
            {...actionItemDialogProps}
            onPrimary={parseAndDownload}
            translate={t}
        >
            {description !== undefined ? (
                <Typography variant="body2" pb={2}>
                    {description}
                </Typography>
            ) : null}
            <FormGroup>
                <TextField
                    value={filename}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setFilename(event.target.value)}
                    fullWidth={true}
                    label={t(translationKeys.VDLANG_FILENAME)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">.csv</InputAdornment>,
                    }}
                />
                <Tooltip title={t(translationKeys.VDLANG_EXPORT_CSV_TOGGLE_EXCEL_METADATA_HINT)}>
                    <FormControlLabel
                        control={
                            <Switch checked={isExcelMetadataEnabled} onChange={() => setIsExcelMetadataEnabled(!isExcelMetadataEnabled)} />
                        }
                        label={t(translationKeys.VDLANG_EXPORT_CSV_TOGGLE_EXCEL_METADATA)}
                    />
                </Tooltip>

                <FormControlLabel
                    control={<Switch checked={optimizeForPrint} onChange={() => setOptimizeForPrint(!optimizeForPrint)} />}
                    label={t(translationKeys.VDLANG_EXPORT_CSV_TOGGLE_PRINT_FORMATTING)}
                />

                <Select
                    label={t(translationKeys.VDLANG_EXPORT_CSV_LANGUAGE)}
                    isMulti={false}
                    options={languageOptions}
                    value={selectedLanguageOption}
                    onChange={handleLanguageChange}
                    placeholder={t(translationKeys.VDLANG_SELECT_PLEASE_SELECT)}
                    menuPortalTarget={document.body}
                />
            </FormGroup>
        </ActionItemDialog>
    );
};

export default ExportCsvDialog;
